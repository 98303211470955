import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import { Grid } from '@material-ui/core'
import './_custom_select.scss'
export const CustomSelect = forwardRef((props, ref) => {
  const { label, data, name, value ,setParentSelectedValue, handleChange, disabled, style, floatRight } = props
  const [isSelectedOnce,setSelectedOnceState]=useState(false)
  const [selectedValue, setValue] = useState(0)
  useEffect(() => {
    if (value !== undefined && value!==null) {
      if (/[^$,.\d]/.test(value)){
        if(typeof(value)==='boolean'){
          if(value){
            setValue(2)
            if(handleChange!==undefined){
              handleChange({name:name,value:2})
            }  
          }
          else{
            setValue(1)
            if(handleChange!==undefined){
              handleChange({name:name,value:1})
            }
          }
        }
        else{
          let id=data.find(item=>item.title===value).id
          setValue(id)
          if(handleChange!==undefined){
            handleChange({name:name,value:id.toString()})
          }
        }
      }
      else{
        setValue(value)
      }
    }
  }, [value,handleChange])
  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue
    },
    setValueToEmpty() {
      setValue(0)
    },
  }))
  const handleChangeSelect = (event) => {
    const value=event.target.value
    setValue(value)
    setParentSelectedValue(parseInt(value))
    setSelectedOnceState(true)
    if(handleChange!==undefined){
      handleChange({name:name,value:event.target.value})
    }
  }
  return (
    <Grid container justify = {floatRight && 'flex-end'}>
      <div className="flex-row width-auto">
        <p className = {`custom-select-label width-auto ${floatRight && 'right-text'}`}>{label}</p>
      </div>
      <select
        name={name}
        value={selectedValue}
        onChange={handleChangeSelect}
        disabled = {disabled}
        style = {style && style}
        className={`custom-input-box animatable-400 ease-in-out-quart
                                ${
                                  selectedValue === 0 && isSelectedOnce
                                    ? 
                                      'input-error-border'
                                    : ''
                                } `}
      >
        <option disabled hidden value={0}></option>
        {data !== undefined && data[0].length > 0
          ? data[0].map((item) => (
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
            ))
          : data.map((item) => 
              <option value={item.id} key={item.id}>
                {item.title}
              </option>
            )}
      </select>
    </Grid>
  )
})

CustomSelect.defaultProps={
  setParentSelectedValue:function(value){}
}