import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Grid } from '@material-ui/core'
import './_custom_checkbox.scss'

export const CustomCheckBox = forwardRef((props, ref) => {
  const { label, value, disableAllButtons, disabled } = props
  const [isChecked, setValue] = useState(false)

  useEffect(()=>{
    if(value!==undefined){

      setValue(value)
    }
  },[value])

  useImperativeHandle(ref, () => ({
    getValue() {
      return isChecked
    },
    disableButton(){
      setValue(false)
    }
  }))

  const handleChange = () => {
    if(!isChecked){
      disableAllButtons()
      setValue(true)
    }
    else{
      setValue(false)
    }
    
  }
  return (
    <Grid direction="column">
      <div className="flex center-content cursor-pointer">
        <div
         
          className={`epb-service-checkbox-${
            isChecked ? 'filled' : 'outline'
          } center-content`}
          onClick={(!disabled ) ? () => {
              handleChange()
          } : null}
        >
          {isChecked && (
            <img
              alt = 'check_button'
              className="epb-service-checkbox-filled-image"
              src={require('../../../assets/svg/icon/button_check.svg')}
            />
          )}
        </div>
        <span className="font-color-black font-size-14 margin-left-10 vietnam-regular">
          {label}
        </span>
      </div>
    </Grid>
  )
})

CustomCheckBox.defaultProps={
  disableAllButtons:()=>{}
}