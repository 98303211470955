import React, { useState, useEffect } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import PropTypes from "prop-types";
import './_row_details.scss'

export default function RowDetails(props) {
  const { file, userDetails, index, deleteFile } = props
  const [isImageClicked, setImageState] = useState(false)
  const [fileHover, setFileHover] = useState(0)
  const [showMenu, setShowMenu] = useState(0)
  const [ renderItem, setRenderItem ] = useState(false)

  useEffect(() => {
    if(typeof file === "object" && file.file !== undefined){
      setRenderItem(true)
    }
  }, [file])
  const handleFileDelete = () => {
    let data = {
      index: index,
      documentId: file.id,
    }
    deleteFile(data)
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      justify="center"
      alignItems="center"
      className="margin-top-50"
      onMouseOver={() => {
        setFileHover(index + 1)
      }}
      onMouseLeave={() => {
        setFileHover(0)
      }}
    >
      {renderItem && (
        <>
          <Grid container md={1} xs={1} sm={1} justify = 'flex-start'>
            <Grid
              container
              item
              md={5}
              xs={5}
              sm={5}
              justify="flex-end"
              alignItems="center"
            >
              {!file.userRead && <div className="documents-panel-file-notif" />}
            </Grid>
            <Grid
              container
              item
              md={5}
              xs={5}
              sm={5}
              justify="center"
              alignItems="center"
            >
              {file.path !== undefined && file.path !== null ? (
                <img
                  alt=""
                  className="document-details-image"
                  src={
                    file.path.split('.')[1] === 'pdf'
                      ? require('../../../../../../assets/svg/doc/pdf.svg')
                      : file.path.split('.')[1].includes('doc')
                      ? require('../../../../../../assets/svg/doc/doc.svg')
                      : file.path.split('.')[1].includes('xls')
                      ? require('../../../../../../assets/svg/doc/xls.svg')
                      : file.path.split('.')[1].includes('jpg')
                      ? require('../../../../../../assets/svg/doc/jpg.svg')
                      : require('../../../../../../assets/svg/doc/other.svg')
                  }
                />
              ) : (
                <>
                  {file.file !== null && (
                    <img
                      alt=""
                      className="document-details-image"
                      src={
                        file.file.split('.')[1] === 'pdf'
                          ? require('../../../../../../assets/svg/doc/pdf.svg')
                          : file.file.split('.')[1].includes('doc')
                          ? require('../../../../../../assets/svg/doc/doc.svg')
                          : file.file.split('.')[1].includes('xls')
                          ? require('../../../../../../assets/svg/doc/xls.svg')
                          : file.file.split('.')[1].includes('jpg')
                          ? require('../../../../../../assets/svg/doc/jpg.svg')
                          : require('../../../../../../assets/svg/doc/other.svg')
                      }
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={7} sm={3} md={3}>
            <p className="document-details-file-name vietnam-regular">
              {file.title !== null ? file.title : '-'}
            </p>
          </Grid>
          <Hidden xsDown>
            <Grid container item xs={3} sm={3} md={3}>
              <p className="document-details-person-name vietnam-regular">
                {file.person !== undefined ? file.person : userDetails.firstName}
              </p>
            </Grid>
            <Grid container item xs={3} sm={2} md={2}>
              <p className="document-details-label vietnam-regular">
                {file.label !== null ? file.label : '-'}
              </p>
            </Grid>
          </Hidden>
          <Grid container item xs={4} sm={3} md={3} >
            <div className = 'flex-row vertical-center'>
              <p className="document-details-date-time vietnam-regular ">
                {file.createdAt !== null ? file.createdAt.split(' ')[0] : '-'}
              </p>
              <div
              className="documents-file-menu flex-column vertical-center animatable-400 ease-in-out-quart"
              style={{
                opacity: fileHover === index + 1 || showMenu === index + 1 ? 1 : 0,
                transform:
                  fileHover === index + 1 || showMenu === index + 1
                    ? 'translateX(0px)'
                    : 'translateX(15px)',
              }}
              onBlur={()=>{setShowMenu(0)}}
            >
              <button
                type="button"
                className="flex-column cursor-pointer"
                onClick={() => {
                  if (showMenu === index + 1) {
                    setShowMenu(0)
                  } else setShowMenu(index + 1)
                }}
              >
                <div className="documents-file-menu-dot" />
                <div className="documents-file-menu-dot" />
                <div className="documents-file-menu-dot" />
              </button>
              <div
                className="documents-file-menu-modal-container flex-column animatable-400 ease-in-out-quart"
                style={{
                  height:
                    showMenu === index + 1 || showMenu === index + 1
                      ? '160px'
                      : '0px',
                  opacity: showMenu === index + 1 || showMenu === index + 1 ? 1 : 0,
                }}
              
              >
                <div className="flex-row width-auto horizontal-end">
                  <img
                    src={require('../../../../../../assets/svg/icon/corner.png')}
                    alt="corner"
                    className="documents-file-menu-modal-arrow"
                  />
                </div>
                <div className="documents-file-menu-modal flex-column vertical-center">
                  <button
                    type="button"
                    className="documents-file-menu-modal-button documents-file-menu-modal-divider center-content"
                  >
                    <a
                      href={`https://backend.develop.bip.flykube.agifly.cloud/documents/download_file/${file.file}`}
                      download
                      className="main-font-semi-bold font-size-14 font-color-black"
                    >
                      Download
                    </a>
                  </button>
                  <button
                    type="button"
                    className="documents-file-menu-modal-button documents-file-menu-modal-divider center-content"
                  >
                    <a
                      href={`https://backend.develop.bip.flykube.agifly.cloud/uploads/${file.file}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="main-font-semi-bold font-size-14 font-color-black"
                    >
                      View
                    </a>
                  </button>
                  <button
                    type="button"
                    className="documents-file-menu-modal-button center-content"
                  >
                    <h1
                      className="main-font-semi-bold font-size-14 font-color-black"
                      onClick={handleFileDelete}
                    >
                      Delete
                    </h1>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </Grid>

        </>
      )}
    </Grid>
  )
}

RowDetails.propTypes = {
  file: PropTypes.object,
}

RowDetails.defaultProps = {
  file: {
    file: "example.png"
  }
}