import React from 'react'
import { Grid, Dialog } from '@material-ui/core'
import './_profile_view.scss'

export default function ProfileView(props) {
  const { modalState, setModalState } = props
  const userDetails = JSON.parse(localStorage.getItem('loggedInUserDetails')) || null
  //   const [modalState, setModalState] = useState(true)

  const handleButtonClick = () => {
    setModalState(false)
  }

  return (
    <Dialog open={modalState} disableBackdropClick disableEscapeKeyDown>
      <Grid container item className="profile-view-size">
        <div className="profile-view-size">
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            className="profile-container-size"
          >
            <div className="flex-row center-content">
              <img
                alt = 'user'
                className="profile-user-icon"
                src={require('../../../assets/svg/icon/user.svg')}
              />
              <p className="profile-title vietnam-regular">Profiel</p>
            </div>
            <img
              alt = 'cross'
              className="profile-cross-icon"
              src={require('../../../assets/svg/icon/cross.svg')}
              onClick={handleButtonClick}
            />
          </Grid>
          <div className="profile-data-view">
            <Grid container item justify="center">
            {typeof userDetails === "object" && userDetails.company!==null &&
                <Grid container item md={2} justify="center">
                <img
                  alt = 'profile_picture'
                  className="profile-image"
                  src={userDetails.company.profile_picture ? `https://backend.develop.bip.flykube.agifly.cloud/uploads/${userDetails.company.profile_picture}` : require('../../../assets/svg/user.svg')}
                />
              </Grid>
              }
              <Grid
                container
                item
                md={5}
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularding">
                    Naam
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.lastName !== null ? userDetails.lastName : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Voornaam</p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.firstName !== null
                      ? userDetails.firstName
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularg">email</p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.email !== null ? userDetails.email : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">
                    Contact Tel.
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.phone !== null ? userDetails.phone : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Beroep</p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.profession !== null
                      ? userDetails.profession
                      : '-'}
                  </p>
                </Grid>
              </Grid>

              <Grid container item md={5} justify="flex-start" spacing={2}>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Bedrijf</p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.company !== null &&
                    userDetails.company.name !== null
                      ? userDetails.company.name
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularding">
                    BTW nummer
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.company !== null &&
                    userDetails.company.BTW !== null
                      ? userDetails.company.BTW
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regularg">
                    Algemeen email
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.company !== null &&
                    userDetails.company.generalEmail !== null
                      ? userDetails.company.generalEmail
                      : '-'}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">
                    Algemeen Tel.
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.company !== null &&
                    userDetails.company.generalTelephone !== null
                      ? userDetails.company.generalTelephone
                      : null}
                  </p>
                </Grid>
                <Grid container item direction="column" alignItems="flex-start">
                  <p className="info-tab-heading vietnam-regular">Adres</p>
                  <p className="info-tab-details vietnam-regular">
                    {typeof userDetails === "object" && userDetails.company !== null &&
                    userDetails.company.companyAddress !== null
                      ? userDetails.company.bus == "" || userDetails.company.bus == null ? userDetails.company.companyAddress+" "+userDetails.company.no+" "+userDetails.company.postalCode+" "+userDetails.company.city: userDetails.company.companyAddress+" "+userDetails.company.no+"/"+userDetails.company.bus+" "+userDetails.company.postalCode+" "+userDetails.company.city
                      : '-'}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </Dialog>
  )
}
