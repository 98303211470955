import React, { createRef, useEffect, useState } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import './_info_tab.scss'
import {CustomInput} from '../../../../shared_components/custom_input/custom_input'
import { CustomSelect } from '../../../../shared_components/custom_select/custom_select'
import Popup from '../../../../shared_components/custom_popup/custom_popup.js';

export default function InfoTab(props) {
  const {
    token,
    userRole,
    buildingId,
    serviceId,
    projectId,
    buildingData,
    projectServiceData,
    singleBuildingData
  } = props

  const [ editMode, setEditMode ] = useState(false)
  const [ companyName, setCompanyName ] = useState("")
  const [ contactName, setContactName ] = useState("")
  const [ contactProfession, setContactProfession ] = useState("")
  const [ contactTelephone, setContactTelephone ] = useState("")
  const [ companyTelephone, setCompanyTelephone ] = useState("")
  const [ contactEmail, setContactEmail ] = useState("")
  const [ companyDescription, setCompanyDescription ] = useState("")

  const [ companyNameError, setCompanyNameError ] = useState("")
  const [ contactNameError, setContactNameError ] = useState("")
  const [ contactProfessionError, setContactProfessionError ] = useState("")
  const [ contactTelephoneError, setContactTelephoneError ] = useState("")
  const [ companyTelephoneError, setCompanyTelephoneError ] = useState("")
  const [ contactEmailError, setContactEmailError ] = useState("")
  const [data, addData] = useState([])
  const [facturateFormRefs, setFacturateFormRefs] = useState([])
  const [facturateData, setFacturateData] = useState([])
  const [companyData, setCompanyData] = useState({})
  const [showResponseState, setShowResponseState] = useState(false)
  const [responseMessageState, setResponseMessageState] = useState({})
  const [isFacturateEditable, setFacturateEditState] = useState(true)
  const [ dateHoverState, setDateHoverState ] = useState(false)
  const [newContactPersonForm, setNewPersonForm] = useState({
    id: 0,
    buildingId: 0,
    first_name: '-',
    last_name: '-',
    email: '-',
    phoneNumber: '-',
    profession: '-'
  })
  const [newContactFormEditable, setContactFormEditableState] = useState(false)
  const history = useHistory()

  useEffect(() => {
    let tempArray = []
    Array(11 * data.length).fill().map(item => {
      tempArray.push(createRef(0))
    })  
    setFacturateFormRefs(tempArray)
  }, [data])

  useEffect(() => {
    createFileNumber()
    if(buildingData.company) {
      setCompanyName(buildingData.company.name)
      setContactName(buildingData.company.user[0].firstName);
      setContactProfession(buildingData.company.user[0].profession);
      setContactTelephone(buildingData.company.user[0].phone)
      setCompanyTelephone(buildingData.company.generalTelephone)
      setContactEmail(buildingData.company.user[0].email)
      setCompanyDescription(buildingData.company.description)
    }
  }, [buildingData,serviceId,projectId, projectServiceData])

  function createFileNumber(){
    let newBuildingId = ''
    let newServiceId = ''
    if(serviceId!==undefined && buildingId !==undefined ){
      let buildingNumberLength = 4 - (buildingId.toString().length)
        let serviceNumberLength = 4 - (serviceId.toString().length)
        
        if(buildingNumberLength === 1){
          newBuildingId = '0' + buildingId
        }
        else if(buildingNumberLength === 2){
          newBuildingId = '00' + buildingId
        }
        else if(buildingNumberLength === 3){
          newBuildingId = '000' + buildingId
        }
        if(serviceNumberLength === 1){
          newServiceId = '0' + serviceId
        }
        else if(serviceNumberLength === 2){
          newServiceId = '00' + serviceId
        }
        else if(serviceNumberLength === 3){
          newServiceId = '000' + serviceId
        }
  }
  return newBuildingId+'-'+newServiceId
  }
  
  function validateFields() {
    let validate = true;
    let telephoneRegex = new RegExp(/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/, 'g');
    if(companyName === "") {
      setCompanyNameError(true);
      validate = false;
    }
    if(contactName === "") {
      setContactNameError(true);
      validate = false;
    }
    if(contactProfession === "") {
      setContactProfessionError(true)
      validate = false;
    }
    if(contactTelephone === "" && telephoneRegex.test(contactTelephone)) {
      setContactTelephoneError(true);
      validate = false;
    }
    if(companyTelephone === "" && telephoneRegex.test(companyTelephone)) {
      setCompanyTelephoneError(true);
      validate = false;
    }
    if(contactEmail === "" && /[a-zA-Z0-9À-ú-_\.]+@[a-zA-Z0-9À-ú-_\.]+\.[a-zA-Z]+/g.test(contactEmail)) {
      setContactEmailError(true);
      validate = false;
    }
    return validate;
  }

  function resetErrors() {
    companyNameError && setCompanyNameError(false);
    contactNameError && setContactNameError(false);
    contactProfessionError && setContactProfessionError(false)
    contactTelephoneError && setContactTelephoneError(false)
    companyTelephoneError && setCompanyTelephoneError(false)
    contactEmailError && setContactEmailError(false)
  }

  const updateCompany = () => {
    if(editMode && validateFields()) {
      axios.post('https://backend.develop.bip.flykube.agifly.cloud/admin/company/add', {
        companyInfo: {
          id: buildingData.company.id,
          BTW: buildingData.company.BTW,
          name: companyName,
          generalTelephone: companyTelephone,
          description: companyDescription || ''
        },
        contactInfo: {
          id: buildingData.company.user[0].id,
          firstName: contactName,
          profession: contactProfession,
          phone: contactTelephone,
          email: contactEmail,
          password: buildingData.company.user[0].password
        }
      }, {
        headers: {
          authorization: `bearer ${token}`
        }
      }).then(response => {
        console.log(response.data)
        history.go(0)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    if(singleBuildingData){
      if(singleBuildingData.contact){
        setNewPersonForm(singleBuildingData.contact)
      }
      setCompanyData(singleBuildingData.user)
    }
  } , [singleBuildingData])

  const handleSaveClick = () => {
    data.map((item, i) => {
      if(i<2){
        item.companyName = facturateFormRefs[(i*11)+0].current !== null ? facturateFormRefs[(i*11)+0].current.getValue() : item.companyName
        item.name = facturateFormRefs[(i*11)+1].current !== null ? facturateFormRefs[(i*11)+1].current.getValue() : item.name
        item.BTW = facturateFormRefs[(i*11)+2].current !== null ? facturateFormRefs[(i*11)+2].current.getValue() : item.BTW
        item.firstName = facturateFormRefs[(i*11)+3].current !== null ? facturateFormRefs[(i*11)+3].current.getValue() : item.firstName
        item.emailAddress = facturateFormRefs[(i*11)+4].current.getValue()
        item.address = facturateFormRefs[(i*11)+5].current.getValue()
        item.no = facturateFormRefs[(i*11)+6].current.getValue()
        item.bus = facturateFormRefs[(i*11)+7].current.getValue()
        item.postalCode = facturateFormRefs[(i*11)+8].current.getValue()
        item.city = facturateFormRefs[(i*11)+9].current.getValue()
        item.billingPercent = data.length > 1 ? facturateFormRefs[(i*11)+10].current.getValue() : 100
      }
    })
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/company_billing/store_billing_by_id`,
          {
            id: companyData.id,
            dataArray: data,
          },
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.data.message === 'Billing data saved') {
            let responseMessage = {
              success: true,
              heading: '',
              message: ''
            }
            
            setShowResponseState(true)
            responseMessage = {
              isSuccess: true,
              heading: 'Succesrecord opgeslagen',
              message: 'Bedrijf en zijn facturering zijn bijgewerkt',
              popupState: setShowResponseState
            }
            setResponseMessageState(responseMessage) 
            setFacturateEditState(!isFacturateEditable)         }
          })
        .catch((error) => {
         
        })  
     
  }

  useEffect(()=>{
    var arr = []
    var i = 0
    while (i < 101) {
      arr.push({id: i, title: i})
      i += 5
    }
    setFacturateData(arr)
      if(companyData || projectServiceData){
        getCompanyDetails()
      }
  },[companyData, serviceId, projectServiceData])

  const getCompanyDetails = () => {
    if(serviceId){
      let responseData = []
      axios
        .post(
          `https://backend.develop.bip.flykube.agifly.cloud/project_services/comp_billing_by_ps`,
          {
            projectServiceId: serviceId,
          },  
          { headers: { authorization: `bearer ${token}` } },
        )
        .then((response) => {
          if (response.status === 200) {
            responseData = response.data.data          
            addData(responseData)
          }
        })
        .catch((error) => {})
    } else if(projectServiceData){
        let responseData = []
        axios
          .post(
            `https://backend.develop.bip.flykube.agifly.cloud/project_services/comp_billing_by_ps`,
            {
              projectServiceId: projectServiceData.id,
            },  
            { headers: { authorization: `bearer ${token}` } },
          )
          .then((response) => {
            if (response.status === 200) {
              responseData = response.data.data          
              addData(responseData)
            }
          })
          .catch((error) => {})
    }
  }

  const saveNewContact = () => {
    let tempNewContactPerson = JSON.parse(JSON.stringify(newContactPersonForm))
    tempNewContactPerson.buildingId = singleBuildingData.id
    axios
      .post(
        `https://backend.develop.bip.flykube.agifly.cloud/buildlings/addNewContact`,
        tempNewContactPerson,
        { headers: { authorization: `bearer ${token}` } },
      )
      .then((response) => {
        if(response.data.message === 'Success!'){
          let responseMessage = {
            success: true,
            heading: '',
            message: ''
          }
          
          setShowResponseState(true)
          responseMessage = {
            isSuccess: true,
            heading: 'Succesrecord opgeslagen',
            message: '',
            popupState: setShowResponseState
          }
          setResponseMessageState(responseMessage) 
          setContactFormEditableState(!newContactFormEditable)
        }
      })
      .catch((error) => {
        setShowResponseState(true)
        let responseMessage = {
          isSuccess: false,
          heading: 'Record niet opgeslagen',
          message: error.response.data.message ? error.response.data.message : 'Er is iets misgegaan',
          popupState: setShowResponseState
        }
        setResponseMessageState(responseMessage)
      })
  }

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      justify="center"
      className = 'overflow-y-auto overflow-x-hidden height-100-p'
    >
      <Grid container>
        <div className="margin-top-10" />
      </Grid>
      {buildingData !== undefined &&
        (userRole === 'Buyer/Seller' ? (
          <Grid container item justify="center" className = 'padding-top-10 padding-bottom-10'>
              <Grid container md={6} alignItems = 'flex-start'>
                <Grid container item >
                  <div>
                    <p className="info-tab-heading vietnam-regular">Status</p>
                    <img
                      alt = 'status'
                      className = 'width-8'
                      src={
                        projectServiceData.service_status_id === 0
                          ? require('../../../../../assets/svg/Status/ontvangen.svg')
                          : projectServiceData.service_status_id === 1
                          ? require('../../../../../assets/svg/Status/behandeling.svg')
                          : projectServiceData.service_status_id === 2
                          ? require('../../../../../assets/svg/Status/afgewerkt.svg')
                          : projectServiceData.service_status_id === 3
                          ? require('../../../../../assets/svg/Status/onhold.svg')
                          : projectServiceData.service_status_id === 4
                          ? require('../../../../../assets/svg/Status/geannuleerd.svg')
                          : projectServiceData.service_status_id === 5
                          ? require('../../../../../assets/svg/Status/offerte.svg')
                          : projectServiceData.service_status_id === 6
                          ? require('../../../../../assets/svg/Status/offerteaanvaard.svg')
                          : projectServiceData.service_status_id === 7
                          ? require('../../../../../assets/svg/Status/gefactureerd.svg')
                          : require('../../../../../assets/svg/Status/betaald.svg')
                      }
                    />
                  </div>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regular">Dienst</p>
                  <p className="info-tab-details vietnam-regular">
                    {projectServiceData.service === null ? '-' : projectServiceData.service.title}
                  </p>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regular">
                    Dossiernummer
                  </p>
                  <p className="info-tab-details vietnam-regular">
                    {createFileNumber()}
                  </p>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">Type</p>
                  <p className="info-tab-details vietnam-regular">
                  {projectServiceData.servicesMeta === null
                      ? '-'
                      : projectServiceData.servicesMeta.title}
                  </p>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regular">Uitvoerder</p>
                  <p className="info-tab-details vietnam-regular">
                    {buildingData.performer === null
                      ? '-'
                      : buildingData.performer}
                  </p>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularng">Telefoon</p>
                  <p className="info-tab-details vietnam-regular">
                    {buildingData.phone === null ? '-' : buildingData.phone}
                  </p>
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regular">Beschrijving</p>
                  <p className="info-tab-details vietnam-regular">
                    {buildingData.description === null
                      ? '-'
                      : buildingData.description}
                  </p>
                </Grid>
              </Grid>
              <Hidden mdUp>
                <Grid container><div className = 'margin-top-10'/></Grid>
              </Hidden>
              <Grid container md={6} alignItems = 'flex-start'>
                {projectServiceData.service.title === 'EPB - Verslaggeving' ||
                  projectServiceData.service.title === 'Ventilatieverslaggeving' ||
                  projectServiceData.service.title === 'Veiligheidscoördinatie' ? (
                    <Grid container xs={10} sm={8} md={6} justify='center' alignItems = 'flex-end' >
                      <Grid container item sm = {12}>
                        <div className = "margin-top-10" />
                      </Grid>
                      <button
                        type="button"
                        className="selected-tab-button-style "
                        onClick={
                          projectServiceData.service.title === 'EPB - Verslaggeving'
                            ? () => {
                                history.push(`/epb-service/${serviceId}-${projectId}`)
                              }
                            : projectServiceData.service.title === 'Ventilatieverslaggeving'
                            ? () => {
                                history.push(`/ventilation-service/${serviceId}-${projectId}`)
                              }
                            : () => {
                                history.push(`/safety-service/${serviceId}-${projectId}`)
                              }
                        }
                      >
                        Dossierverloop
                      </button>
                      <Grid container item sm = {12}>
                        <div className = "margin-top-15" />
                      </Grid>
                    </Grid>
                    ) : (
                    <React.Fragment></React.Fragment>
                )}
                <Grid container item sm = {12} justify = "space-between">
                  <div className = "relative cursor-pointer" onMouseOver = {() => setDateHoverState(true) } onMouseLeave = {() => setDateHoverState(false) } >
                    <div>
                      <p className="info-tab-heading vietnam-regular">Inspectie Datum</p>
                      <p className="info-tab-details vietnam-regular">
                        {projectServiceData && projectServiceData.billing_date
                          ? projectServiceData.billing_date?.split(' ')[0]
                          : '-'
                        }
                      </p>
                    </div>
                    <div className = "info-tab-datum-dropdown animatable-400 ease-in-out-quart" style = {{height: dateHoverState ? '52px' : '0px', opacity: dateHoverState ? 1 : 0}}>
                      <div className = "width-auto flex-column">
                        <p className = "main-font-regular font-size-14 font-color-grey">Van</p>
                        <p className = "main-font-semi-bold font-size-14 font-color-black">{projectServiceData && projectServiceData.billing_start_time ? projectServiceData.billing_start_time.substring(0, 5) : '-'}</p>
                      </div>
                      <div className = "flex-row">
                        <div className = "info-tab-datum-line" />
                      </div>
                      <div className = "width-auto flex-column">
                        <p className = "main-font-regular font-size-14 font-color-grey">Tot</p>
                        <p className = "main-font-semi-bold font-size-14 font-color-black">{projectServiceData && projectServiceData.billing_end_time ? projectServiceData.billing_end_time.substring(0, 5) : '-'}</p>
                      </div>
                    </div>
                  </div>
                  <div className = "info-tab-pencil cursor-pointer" onClick = {() => { setEditMode(!editMode) }} >
                    <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
                  </div>
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regular">Bedrijf</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {companyName} onChange = {(event) => { setCompanyName(event.target.value); resetErrors(); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {companyName || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">Contactpersoon</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {contactName} onChange = {(event) => { setContactName(event.target.value); resetErrors(); }} />
                    </div>
                  ): (
                    <p className="info-tab-details vietnam-regular">
                      {contactName || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">Beroep</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {contactProfession} onChange = {(event) => { setContactProfession(event.target.value); resetErrors(); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {contactProfession || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">Telefoon</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {companyTelephone} onChange = {(event) => { setCompanyTelephone(event.target.value); resetErrors(); }} />
                    </div>
                  ): (
                    <p className="info-tab-details vietnam-regular">
                      {companyTelephone || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">Kantoor tel.</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {contactTelephone} onChange = {(event) => { setContactTelephone(event.target.value); resetErrors(); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {contactTelephone || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item direction="column">
                  <p className="info-tab-heading vietnam-regularg">E-mail</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input-buyer main-font-semi-bold font-size-14 font-color-black" value = {contactEmail} onChange = {(event) => { setContactEmail(event.target.value); resetErrors(); }} />
                    </div>
                  ): (
                    <p className="info-tab-details vietnam-regular">
                      {contactEmail || "-"}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                </Grid>
                <Grid container item sm = {12} justify = "flex-end" className = "relative">
                  <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: editMode ? 1 : 0, transform: editMode ? 'translateX(0px)' : 'translateX(15px)'}}>
                    <div className = "info-tab-save center-content" onClick = {updateCompany} >
                      <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                    </div>
                    <div className = "height-auto width-auto absolute" style = {{display: editMode ? 'none' : 'flex'}} />
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                <div className="margin-top-10" />
              </Grid>
              {
              showResponseState === true ?
              <Popup data={responseMessageState}/>
              : null
              }
              <Grid container item sm = {12} justify = "flex-end">
                <div className = "info-tab-pencil cursor-pointer margin-top-10" onClick = {() => { setFacturateEditState(!isFacturateEditable) }} >
                  <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
                </div>
              </Grid>
              <Grid container sm = {12} md = {12}>
                  <p className="font-size-18 main-font-semi-bold font-color-black vietnam-regular ">Facturatie info</p>
              </Grid>
              <Grid container sm = {12} md = {12} justify = 'flex-end' >
                <Grid container >
                  {data.map((item, index) =>
                    index < 2 ? (
                      
                      <Grid container item md={6} sm={6} >
                        {item.isCompany ? (
                          
                          <CustomInput label = 'Bedrijf' ref = {facturateFormRefs[(index*11)+0]} disabled = {isFacturateEditable} value = {item.companyName} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        ) : (
                          
                          <CustomInput label = 'Naam' ref = {facturateFormRefs[(index*11)+1]} disabled = {isFacturateEditable} value = {item.name} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        )}
                        {item.isCompany ? (
                          
                          <CustomInput label = 'BTW' ref = {facturateFormRefs[(index*11)+2]} disabled = {isFacturateEditable} value = {item.BTW} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        ) : (
                          
                          <CustomInput label = 'Voornaam' ref = {facturateFormRefs[(index*11)+3]} disabled = {isFacturateEditable} value = {item.firstName} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        )}
                        <Grid container>
                        
                          <CustomInput label = 'emailAddress' ref = {facturateFormRefs[(index*11)+4]} disabled = {isFacturateEditable} value = {item.emailAddress} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid>
                        <Grid container >
                          <CustomInput label = 'Adres' ref = {facturateFormRefs[(index*11)+5]} disabled = {isFacturateEditable} value = {item.address} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid>
                        <Grid container >                    
                          <CustomInput label = 'Nr.' ref = {facturateFormRefs[(index*11)+6]} disabled = {isFacturateEditable} value = {item.no} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid>
                        <Grid container >  
                          <CustomInput label = 'Bus' ref = {facturateFormRefs[(index*11)+7]} disabled = {isFacturateEditable} value = {item.bus} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid> 
                        <Grid container >
                          <CustomInput label = 'Postcode' ref = {facturateFormRefs[(index*11)+8]} disabled = {isFacturateEditable} value = {item.postalCode} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid>
                        <Grid container >
                          
                            <CustomInput label = 'Gemeente' ref = {facturateFormRefs[(index*11)+9]} disabled = {isFacturateEditable} value = {item.city} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                        </Grid>
                        {data.length > 1 && (
                          <Grid container>
                              {!isFacturateEditable ? (
                                <CustomSelect label = 'Facturate%' data = {facturateData} disabled = {isFacturateEditable} value = {item.billingPercent} ref = {facturateFormRefs[(index*11)+10]} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px', textAlignLast: 'end', width: 'auto' }} />
                              ) : (
                                <CustomInput label = 'Facturate%' ref = {facturateFormRefs[(index*11)+10]} disabled = {isFacturateEditable} value = {item.billingPercent} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px', width: 'auto' }} />
                              )}
                          </Grid>
                          
                        )}
                      </Grid>
                      
                    ) : null,
                  )}
                  <Grid container>
                    <div className = 'margin-top-10' />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item sm = {12} justify = "flex-end" className = "relative">
                  <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: !isFacturateEditable ? 1 : 0, transform: !isFacturateEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
                    <div className = "info-tab-save center-content" onClick = {handleSaveClick} >
                      <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                    </div>
                    <div className = "height-auto width-auto absolute" style = {{display: !isFacturateEditable ? 'none' : 'flex'}} />
                  </div>
              </Grid>
              <Grid container item sm = {12} justify = "flex-end">
                <div className = "info-tab-pencil cursor-pointer margin-top-10" onClick = {() => { setContactFormEditableState(!newContactFormEditable) }} >
                  <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
                </div>
              </Grid>
              <Grid container sm = {12} md = {12}>
                <p className="font-size-18 main-font-semi-bold font-color-black vietnam-regular ">Contactpersoon</p>
              </Grid>
              <Grid container sm = {12} md = {12} >
                <Grid container sm = {8} md = {6}>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                  <Grid container direction='column' >
                    <p className="info-tab-heading vietnam-regular">Voornaam</p>
                    {newContactFormEditable ? (
                      <div className = "width-auto">
                        <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.first_name} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, first_name: event.target.value}); }} />
                      </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular">
                        {newContactPersonForm.first_name}
                      </p>
                    )}
                  </Grid>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                  <Grid container direction='column' >
                    <p className="info-tab-heading vietnam-regular">Achternaam</p>
                    {newContactFormEditable ? (
                      <div className = "width-auto">
                        <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.last_name} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, last_name: event.target.value}); }} />
                      </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular">
                        {newContactPersonForm.last_name}
                      </p>
                    )}
                  </Grid>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                  <Grid container direction='column' >
                    <p className="info-tab-heading vietnam-regular">Email</p>
                    {newContactFormEditable ? (
                      <div className = "width-auto">
                        <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.email} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, email: event.target.value}); }} />
                      </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular">
                        {newContactPersonForm.email}
                      </p>
                    )}
                  </Grid>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                  <Grid container direction='column' >
                    <p className="info-tab-heading vietnam-regular">Telefoon</p>
                    {newContactFormEditable ? (
                      <div className = "width-auto">
                        <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.phoneNumber} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, phoneNumber: event.target.value}); }} />
                      </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular">
                        {newContactPersonForm.phoneNumber}
                      </p>
                    )}
                  </Grid>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                  <Grid container direction='column' >
                    <p className="info-tab-heading vietnam-regular">Beroep</p>
                    {newContactFormEditable ? (
                      <div className = "width-auto">
                        <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.profession} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, profession: event.target.value}); }} />
                      </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular">
                        {newContactPersonForm.profession}
                      </p>
                    )}
                  </Grid>
                  <Grid container item sm = {12}>
                  <div className = "margin-top-15" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item sm = {12} className = "relative">
                <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: newContactFormEditable ? 1 : 0, transform: newContactFormEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
                  <div className = "info-tab-save center-content" onClick = {saveNewContact} >
                    <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                  </div>
                  <div className = "height-auto width-auto absolute" style = {{display: newContactFormEditable ? 'none' : 'flex'}} />
                </div>
              </Grid>

            </Grid>  
        ) : (
          <Grid container justify = 'center'  style = {{height: '80vh', overflowY: 'auto', overflowX: 'hidden', paddingRight: 10}}>
            {/* <Grid xs={1} sm={1} md={1}></Grid> */}
            <Grid xs={12} sm={11} md={11}>
              <Grid container item>
                <Grid container md={9} xs={8} sm={9}>
                  <div>
                    <p className="info-tab-heading vietnam-regular">Status</p>
                    {buildingData !== null && (
                      <img
                      alt = 'status'
                      className = 'width-8'
                      src={
                        buildingData.status === 0
                          ? require('../../../../../assets/svg/Status/ontvangen.svg')
                          : buildingData.status === 1
                          ? require('../../../../../assets/svg/Status/behandeling.svg')
                          : buildingData.status === 2
                          ? require('../../../../../assets/svg/Status/afgewerkt.svg')
                          : buildingData.status === 3
                          ? require('../../../../../assets/svg/Status/onhold.svg')
                          : buildingData.status === 4
                          ? require('../../../../../assets/svg/Status/geannuleerd.svg')
                          : buildingData.status === 5
                          ? require('../../../../../assets/svg/Status/offerte.svg')
                          : buildingData.status === 6
                          ? require('../../../../../assets/svg/Status/offerteaanvaard.svg')
                          : buildingData.status === 7
                          ? require('../../../../../assets/svg/Status/gefactureerd.svg')
                          : require('../../../../../assets/svg/Status/betaald.svg')
                      }
                    />
                    )}
                  </div>
                </Grid>
                {buildingData.shift === 'EPB - Verslaggeving' ||
                buildingData.shift === 'Ventilatieverslaggeving' ||
                buildingData.shift === 'Veiligheidscoördinatie' ? (
                  <Grid container xs={4} sm={3} md={3} alignItems = 'flex-end'>
                    <button
                      type="button"
                      className="selected-tab-button-style"
                      onClick={
                        buildingData.shift === 'EPB - Verslaggeving'
                          ? () => {
                              history.push(`/epb-service/${serviceId}-${projectId}`)
                            }
                          : buildingData.shift === 'Ventilatieverslaggeving'
                          ? () => {
                              history.push(`/ventilation-service/${serviceId}-${projectId}`)
                            }
                          : () => {
                              history.push(`/safety-service/${serviceId}-${projectId}`)
                            }
                      }
                    >
                      Dossierverloop
                    </button>
                  </Grid>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item sm = {12} justify = "space-between">
                <div className = "relative cursor-pointer" onMouseOver = {() => setDateHoverState(true) } onMouseLeave = {() => setDateHoverState(false) } >
                  <div>
                    <p className="info-tab-heading vietnam-regular">Inspectie Datum</p>
                    <p className="info-tab-details vietnam-regular">
                      {buildingData ? buildingData.inspectionDate?.split(' ')[0] : '-'}
                    </p>
                  </div>
                  <div className = "info-tab-datum-dropdown animatable-400 ease-in-out-quart" style = {{height: dateHoverState ? '52px' : '0px', opacity: dateHoverState ? 1 : 0}}>
                    <div className = "width-auto flex-column">
                      <p className = "main-font-regular font-size-14 font-color-grey">Van</p>
                      <p className = "main-font-semi-bold font-size-14 font-color-black">{buildingData?.inspectionStartTime ? buildingData?.inspectionStartTime.substring(0, 5) : '-'}</p>
                    </div>
                    <div className = "flex-row">
                      <div className = "info-tab-datum-line" />
                    </div>
                    <div className = "width-auto flex-column">
                      <p className = "main-font-regular font-size-14 font-color-grey">Tot</p>
                      <p className = "main-font-semi-bold font-size-14 font-color-black">{buildingData?.inspectionEndTime ? buildingData?.inspectionEndTime.substring(0, 5) : '-'}</p>
                    </div>
                  </div>
                </div>
                <div className = "info-tab-pencil cursor-pointer" onClick = {() => { setEditMode(!editMode) }} >
                  <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
                </div>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular">Dossiernaam</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.fileName === null
                    ? '-'
                    : buildingData.fileName}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column' justify='flex-end' >
                <p className="info-tab-heading vietnam-regular horizontal-end">Bedrijf</p>
                {editMode ? (
                  <div className = "width-auto horizontal-end">
                    <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {companyName} onChange = {(event) => { setCompanyName(event.target.value); resetErrors(); }} />
                  </div>
                ) : (
                  <p className="info-tab-details vietnam-regular horizontal-end">
                    {buildingData === null || buildingData.company === null
                      ? '-'
                      : buildingData.company?.name
                    }
                  </p>
                )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular">Dienst</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.shift === null
                    ? '-'
                    : buildingData.shift}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">Contactpersoon</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {contactName} onChange = {(event) => { setContactName(event.target.value); resetErrors(); }} />
                    </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular horizontal-end">
                        {buildingData === null || buildingData.company === null
                          ? '-'
                          : buildingData.company?.user[0].firstName}
                      </p>
                    )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regularg">Type</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.typeOfProperty === null
                    ? '-'
                    : buildingData.typeOfProperty}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">Beroep</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {contactProfession} onChange = {(event) => { setContactProfession(event.target.value); resetErrors(); }} />
                    </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular horizontal-end">
                        {buildingData === null || buildingData.company === null
                          ? '-'
                          : buildingData.company?.user[0].profession}
                      </p>
                    )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular">
                  Dossier nummer
                </p>
                <p className="info-tab-details vietnam-regular">
                  {createFileNumber()}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">
                Telefoon
                </p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {contactTelephone} onChange = {(event) => { setContactTelephone(event.target.value); resetErrors(); }} />
                    </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular horizontal-end">
                        {buildingData === null || buildingData.company === null
                          ? '-'
                          : buildingData.company?.user[0].phone}
                      </p>
                    )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular">Uitvoerder</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.performer === null
                    ? '-'
                    : buildingData.performer}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">Kantoor tel.</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {companyTelephone} onChange = {(event) => { setCompanyTelephone(event.target.value); resetErrors(); }} />
                    </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular horizontal-end">
                        {buildingData === null || buildingData.company === null
                          ? '-'
                          : buildingData.company?.generalTelephone}
                      </p>
                    )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regularng">Telefoon</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.phone === null
                    ? '-'
                    : buildingData.phone}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">E-mail</p>
                  {editMode ? (
                    <div className = "width-auto horizontal-end">
                      <input className = "info-tab-input main-font-semi-bold font-size-14 font-color-black" value = {contactEmail} onChange = {(event) => { setContactEmail(event.target.value); resetErrors(); }} />
                    </div>
                    ) : (
                      <p className="info-tab-details vietnam-regular horizontal-end">
                        {buildingData === null || buildingData.company === null
                          ? '-'
                          : buildingData.company?.user[0].email}
                      </p>
                    )}
                </Grid>
              </Grid>
              <Grid container item sm = {12}>
                <div className = "margin-top-15" />
              </Grid>
              <Grid container item >
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular">Beschrijving</p>
                <p className="info-tab-details vietnam-regular">
                  {buildingData === null || buildingData.description === null
                    ? '-'
                    : buildingData.description}
                </p>
                </Grid>
                <Grid container xs={6} sm={6} md={6} direction='column'>
                <p className="info-tab-heading vietnam-regular horizontal-end">Gebruikersgroep</p>
                  <p className="info-tab-details vietnam-regular horizontal-end text-align-end">
                    {companyData?.roleId && companyData?.roleId === 1 ? 'Verkopen & V.' : companyData?.roleId === 2 ? 'Bouwen & V.' : companyData?.roleId === 3 ? 'Admin' : companyData?.roleId === 4 ? 'Superadmin' : companyData?.roleId === 6 ? 'Extern' : '-'}
                  </p>
                </Grid>
              </Grid>
              <Grid container item sm = {12} justify = "flex-end" className = "relative">
                <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: editMode ? 1 : 0, transform: editMode ? 'translateX(0px)' : 'translateX(15px)'}}>
                  <div className = "info-tab-save center-content" onClick = {updateCompany} >
                    <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                  </div>
                  <div className = "height-auto width-auto absolute" style = {{display: editMode ? 'none' : 'flex'}} />
                </div>
              </Grid>
            </Grid>
            {
              showResponseState === true ?
              <Popup data={responseMessageState}/>
              : null
            }
            <Grid container item sm = {11} justify = "flex-end">
              <div className = "info-tab-pencil cursor-pointer margin-top-10" onClick = {() => { setFacturateEditState(!isFacturateEditable) }} >
                <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
              </div>
            </Grid>
            <Grid container sm = {11} md = {11}>
                <p className="font-size-18 main-font-semi-bold font-color-black vietnam-regular ">Facturatie info</p>
            </Grid>
            <Grid container sm = {11} md = {11} justify = 'flex-end' >
              <Grid container >
                {data.map((item, index) =>
                  index < 2 ? (
                    
                    <Grid container item md={6} sm={6} >
                      {item.isCompany ? (
                        
                        <CustomInput label = 'Bedrijf' ref = {facturateFormRefs[(index*11)+0]} disabled = {isFacturateEditable} value = {item.companyName} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      ) : (
                        
                        <CustomInput label = 'Naam' ref = {facturateFormRefs[(index*11)+1]} disabled = {isFacturateEditable} value = {item.name} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      )}
                      {item.isCompany ? (
                        
                        <CustomInput label = 'BTW' ref = {facturateFormRefs[(index*11)+2]} disabled = {isFacturateEditable} value = {item.BTW} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      ) : (
                        
                        <CustomInput label = 'Voornaam' ref = {facturateFormRefs[(index*11)+3]} disabled = {isFacturateEditable} value = {item.firstName} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      )}
                      <Grid container>
                      
                        <CustomInput label = 'emailAddress' ref = {facturateFormRefs[(index*11)+4]} disabled = {isFacturateEditable} value = {item.emailAddress} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid>
                      <Grid container >
                        <CustomInput label = 'Adres' ref = {facturateFormRefs[(index*11)+5]} disabled = {isFacturateEditable} value = {item.address} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid>
                      <Grid container >                    
                        <CustomInput label = 'Nr.' ref = {facturateFormRefs[(index*11)+6]} disabled = {isFacturateEditable} value = {item.no} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid>
                      <Grid container >  
                        <CustomInput label = 'Bus' ref = {facturateFormRefs[(index*11)+7]} disabled = {isFacturateEditable} value = {item.bus} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid> 
                      <Grid container >
                        <CustomInput label = 'Postcode' ref = {facturateFormRefs[(index*11)+8]} disabled = {isFacturateEditable} value = {item.postalCode} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid>
                      <Grid container >
                          <CustomInput label = 'Gemeente' ref = {facturateFormRefs[(index*11)+9]} disabled = {isFacturateEditable} value = {item.city} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px'}} />
                      </Grid>
                      {data.length > 1 && (
                        <Grid container>
                            {!isFacturateEditable ? (
                              <CustomSelect label = 'Facturate%' data = {facturateData} disabled = {isFacturateEditable} value = {item.billingPercent} ref = {facturateFormRefs[(index*11)+10]} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px', textAlignLast: 'end', width: 'auto' }} />
                            ) : (
                              <CustomInput label = 'Facturate%' ref = {facturateFormRefs[(index*11)+10]} disabled = {isFacturateEditable} value = {item.billingPercent} floatRight = {index === 1 && window.screen.width > 600} style = {{height: isFacturateEditable ? 15 : 35, padding: !isFacturateEditable && '0px 15px', width: 'auto' }} />
                            )}
                        </Grid>
                        
                      )}
                    </Grid>
                    
                  ) : null,
                )}
                <Grid container>
                  <div className = 'margin-top-10' />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sm = {11} justify = "flex-end" className = "relative">
                <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: !isFacturateEditable ? 1 : 0, transform: !isFacturateEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
                  <div className = "info-tab-save center-content" onClick = {handleSaveClick} >
                    <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                  </div>
                  <div className = "height-auto width-auto absolute" style = {{display: !isFacturateEditable ? 'none' : 'flex'}} />
                </div>
            </Grid>
            <Grid container item sm = {11} justify = "flex-end">
              <div className = "info-tab-pencil cursor-pointer margin-top-10" onClick = {() => { setContactFormEditableState(!newContactFormEditable) }} >
                <img src = {require('../../../../../assets/svg/icon/edit_blue.svg')} alt = "edit" className = "height-auto width-auto object-fit-contain" />
              </div>
            </Grid>
            <Grid container sm = {11} md = {11}>
              <p className="font-size-18 main-font-semi-bold font-color-black vietnam-regular ">Contactpersoon</p>
            </Grid>
            <Grid container sm = {11} md = {11} >
              <Grid container sm = {8} md = {6}>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
                <Grid container direction='column' >
                  <p className="info-tab-heading vietnam-regular">Voornaam</p>
                  {newContactFormEditable ? (
                    <div className = "width-auto">
                      <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.first_name} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, first_name: event.target.value}); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {newContactPersonForm.first_name}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
                <Grid container direction='column' >
                  <p className="info-tab-heading vietnam-regular">Achternaam</p>
                  {newContactFormEditable ? (
                    <div className = "width-auto">
                      <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.last_name} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, last_name: event.target.value}); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {newContactPersonForm.last_name}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
                <Grid container direction='column' >
                  <p className="info-tab-heading vietnam-regular">Email</p>
                  {newContactFormEditable ? (
                    <div className = "width-auto">
                      <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.email} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, email: event.target.value}); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {newContactPersonForm.email}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
                <Grid container direction='column' >
                  <p className="info-tab-heading vietnam-regular">Telefoon</p>
                  {newContactFormEditable ? (
                    <div className = "width-auto">
                      <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.phoneNumber} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, phoneNumber: event.target.value}); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {newContactPersonForm.phoneNumber}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
                <Grid container direction='column' >
                  <p className="info-tab-heading vietnam-regular">Beroep</p>
                  {newContactFormEditable ? (
                    <div className = "width-auto">
                      <input className = "info-tab-input-contact main-font-semi-bold font-size-14 font-color-black" value = {newContactPersonForm.profession} onChange = {(event) => { setNewPersonForm({...newContactPersonForm, profession: event.target.value}); }} />
                    </div>
                  ) : (
                    <p className="info-tab-details vietnam-regular">
                      {newContactPersonForm.profession}
                    </p>
                  )}
                </Grid>
                <Grid container item sm = {12}>
                <div className = "margin-top-15" />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item sm = {11} className = "relative">
              <div className = "info-tab-save-container center-content animatable-400 ease-in-out-quart relative" style = {{opacity: newContactFormEditable ? 1 : 0, transform: newContactFormEditable ? 'translateX(0px)' : 'translateX(15px)'}}>
                <div className = "info-tab-save center-content" onClick = {saveNewContact} >
                  <p className = "main-font-semi-bold font-size-14 font-color-white">Opslaan</p>
                </div>
                <div className = "height-auto width-auto absolute" style = {{display: newContactFormEditable ? 'none' : 'flex'}} />
              </div>
            </Grid>

          </Grid>
        ))}
    </Grid>
  )
}
