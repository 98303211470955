// @format
// @flow

import * as React from 'react'
import Core from './components/core'

function App() {
  return (
    <div data-testid="app-component">
      <Core />
    </div>
  )
}

export default App
