import React, { useState } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types';
import './_delete_confirm.scss'

const useDeleteStyles = makeStyles({
    paper: {
      width: '30%',
      maxHeight: '80%',
    },
  });
  
  export default function DeleteConfirmation(props) {
    const { id, setIdState, setOpenState, handleDeleteClick } = props
    const classes = useDeleteStyles();
    const [ popupState, setPopupState ] = useState(true)
  
    const handleCancelButtonClick = () => {
        setPopupState(false);
        if(setOpenState !== undefined && setOpenState !== null) {
            setOpenState(false)
        }
        setIdState(0)
    }
    
    const handleYesButtonClick = () => {
      setPopupState(false);
      if(setOpenState !== undefined && setOpenState !== null) {
          setOpenState(false)
      }
      if (id > 0) {
        handleDeleteClick()
      }
    }

    return(
      <Dialog
          open={popupState}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          scroll="paper"
          classes={{paper: classes.paper}}
      >
          <div className="width-auto height-auto font-size-14 flex-column delete-confirm-view-popup">    
            {/* <div className="center-content">
                <img src={DeleteIcon} alt="" className="all-users-icon-button"/>
            </div> */}
            <div className="center-content margin-top-15">
              <span className="font-color-grey main-font-semi-bold">
                Confirm Delete?
              </span>
            </div>
            <div className="width-auto center-content">
              <div className="horizontal-between width-75-p margin-top-25">
                <button className="delete-confirm-no-button cursor-pointer" onClick={handleCancelButtonClick}>
                  No
                </button>
                <button className="delete-confirm-yes-button cursor-pointer" onClick={handleYesButtonClick}>
                  Yes
                </button>
              </div>
            </div>
          </div>
    </Dialog>
    )
  }
  
  DeleteConfirmation.propTypes = {
    idState: PropTypes.number,
    setIdState: PropTypes.func,
    setOpenState: PropTypes.func,
    handleDeleteClick: PropTypes.func
  }
  DeleteConfirmation.defaultProps = {
    idState: 0,
    setIdState: function() {},
    setOpenState: function() {},
    handleDeleteClick: function() {}
  }
  