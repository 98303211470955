import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './core.scss'
import '../scss/fonts.css'
import LoginPage from './loginpage/loginpage'
import Residence from './residence/residence'
// import Dashboard from './dashboard/dashboard'

// Lazy Loading Components | Code Splitting
const DashboardLazy = React.lazy(() => import('./dashboard/dashboard'))
const ProjectApplicationLazy = React.lazy(() => import('./project_application/project_application'))
const epbServiceLazy=React.lazy(()=>import('./epb_forms/epb_service'))
const safetyServiceLazy=React.lazy(()=>import('./safety_service/safety_service'))
const ventilationService = React.lazy(() => import('./ventilation_service/ventilation_service.js'))
export default function Core() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<div>..Loader </div>}>
          <Switch>
            <Route exact path="/residence/:id">
              <Residence />
            </Route>
            <Route exact path="/project-application/:value" component={ProjectApplicationLazy} />
            <Route exact path="/epb-service/:id" component={epbServiceLazy} />
            <Route exact path="/safety-service/:id" component={safetyServiceLazy} />
            <Route exact path="/ventilation-service/:id" component={ventilationService} />
            <Route exact path="/:page" component={DashboardLazy} />
            <Route exact path="/">
              <LoginPage />
            </Route>
          </Switch>
        </Suspense>
      </Switch>
    </Router>
  )
}
